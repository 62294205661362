import ReactGA from "react-ga4";

export type StockFollowButtonClickedComponentName =
  | "stock_details"
  | "discover_trending_stocks"
  | "web_right_menu_trending_stocks"
  | "smart_feed_trending_stocks"
  | "club_stocks"
  | "watchlist_search"
  | "trending_stocks";

export type SectorFollowButtonClickedComponentName =
  | "sector_details"
  | "discover_trending_sectors"
  | "web_right_menu_trending_sectors"
  | "smart_feed_trending_sectors"
  | "club_sectors"
  | "watchlist_sectors"
  | "trending_sectors";

export type UserFollowButtonClickedComponentName =
  | "post_details"
  | "profile"
  | "discover_experts_stockedge_analyst"
  | "discover_experts_market_experts"
  | "discover_experts_domain_experts"
  | "discover_must_follow"
  | "discover_engaging_members"
  | "web_left_menu_must_follow"
  | "web_left_menu_suggested_people"
  | "club_about_members"
  | "smart_feed_stockedge_analyst"
  | "smart_feed_market_experts"
  | "smart_feed_domain_experts"
  | "smart_feed_most_popular"
  | "smart_feed_suggested_people"
  | "smart_feed_people"
  | "stockedge_analysts"
  | "market_experts"
  | "domain_experts"
  | "engaging_members"
  | "most_popular"
  | "suggested_people"
  | "profile_followers_modal"
  | "profile_followings_modal"
  | "poll_voters_modal"
  | "reply_upvote_modal"
  | "reply_downvote_modal"
  | "comment_upvote_modal"
  | "comment_downvote_modal"
  | "post_upvote_modal"
  | "post_downvote_modal";

export type ClubJoinButtonClickedComponentName =
  | "smart_feed_club"
  | "club_details"
  | "club_category"
  | "web_left_menu_clubs"
  | "profile_clubs_modal"
  | "post_details";

export type TopicFollowButtonClickedComponentName =
  | "topic_details"
  | "discover_trending_topics"
  | "web_right_menu_trending_topics"
  | "smart_feed_trending_topics"
  | "club_topics"
  | "trending_topics";

export type PostImpressionComponentName =
  | string
  | "club_feed"
  | "profile_feed"
  | "stock_feed"
  | "sector_feed"
  | "topic_feed";

export type PostImpressionTickStatus =
  | "blue_tick"
  | "green_tick"
  | "golden_tick"
  | "no_tick";

export type UpgradeModalInitialised =
  | "web_left_menu_clubs"
  | "web_left_menu_must_follow"
  | "web_left_menu_suggested_people"
  | "web_right_menu_trending_stocks"
  | "web_right_menu_trending_sectors"
  | "web_right_menu_trending_topics"
  | "club_details"
  | "profile_clubs_modal"
  | "club_category"
  | "smart_feed_club"
  | "post_details"
  | "post"
  | "stock_details"
  | "sector_details"
  | "topic_details"
  | "trending_stocks"
  | "trending_sectors"
  | "trending_topics"
  | "discover_trending_stocks"
  | "discover_trending_sectors"
  | "discover_trending_topics"
  | "smart_feed_trending_stocks"
  | "smart_feed_trending_sectors"
  | "smart_feed_trending_topics"
  | "smart_feed_market_experts"
  | "smart_feed_domain_experts"
  | "smart_feed_stockedge_analyst"
  | "smart_feed_most_popular"
  | "smart_feed_suggested_people"
  | "smart_feed_people"
  | "most_popular"
  | "profile"
  | "stockedge_analysts"
  | "market_experts"
  | "engaging_members"
  | "domain_experts"
  | "suggested_people"
  | "discover_must_follow"
  | "discover_experts_stockedge_analyst"
  | "discover_experts_market_experts"
  | "discover_engaging_members"
  | "discover_experts_domain_experts"
  | "profile_followers_modal"
  | "profile_followings_modal"
  | "poll_voters_modal"
  | "reply_upvote_modal"
  | "reply_downvote_modal"
  | "post_upvote_modal"
  | "post_downvote_modal"
  | "comment_upvote_modal"
  | "comment_downvote_modal"
  | "club_about_members"
  | "marketing_post"
  | "watchlist_search"
  | "watchlist"
  | "choose_audience";

export type ClubButtonClicked =
  | "smart_feed_club"
  | "search_all"
  | "search_clubs"
  | "post"
  | "club_category"
  | "web_left_menu_clubs"
  | "profile_clubs_modal";

export type UserButtonClicked =
  | "discover_experts_stockedge_analyst"
  | "discover_experts_market_experts"
  | "discover_experts_domain_experts"
  | "discover_must_follow"
  | "discover_engaging_members"
  | "web_left_menu_must_follow"
  | "web_left_menu_suggested_people"
  | "club_about_members"
  | "smart_feed_stockedge_analyst"
  | "smart_feed_market_experts"
  | "smart_feed_domain_experts"
  | "smart_feed_most_popular"
  | "smart_feed_suggested_people"
  | "smart_feed_people"
  | "search_all"
  | "search_people"
  | "post"
  | "post_mention"
  | "comment"
  | "comment_mention"
  | "reply"
  | "reply_mention"
  | "sidemenu_must_follow"
  | "stockedge_analysts"
  | "market_experts"
  | "domain_experts"
  | "engaging_members"
  | "profile_clubs_modal"
  | "profile_followers_modal"
  | "profile_followings_modal"
  | "poll_voters_modal"
  | "most_popular";

export type TopicButtonClicked =
  | "discover_trending_topics"
  | "web_right_menu_trending_topics"
  | "smart_feed_trending_topics"
  | "club_topics"
  | "search_all"
  | "search_topics"
  | "post"
  | "post_mention"
  | "comment"
  | "comment_mention"
  | "reply"
  | "reply_mention"
  | "trending_topics";

export type GAEvent =
  | {
      event: "web_left_menu_clicked";
      button_name: "Discover" | "Home" | "Learn" | "Bookmark";
    }
  | {
      event: "post_impression";
      postId: string;
      component_name: PostImpressionComponentName;
      tick_status: PostImpressionTickStatus;
    }
  | {
      event: "mem_level_icon_clicked";
    }
  | {
      event: "mem_level_modal_cta_button_clicked";
    }
  | {
      event: "mem_level_modal_close_button_clicked";
    }
  | {
      event: "club_category_button_clicked";
      component_name: "discover_club_category" | "sidemenu_club_category";
    }
  | {
      event: "profile_power_user_streak_modal_clicked";
    }
  | {
      event: "profile_power_user_streak_week_button_clicked";
    }
  | {
      event: "profile_achievement_clicked";
      achievement_name: string;
    }
  | {
      event: "callback_request_initiated";
    }
  | {
      event: "callback_request_completed";
    }
  | {
      event: "burger_menu_initialized";
    }
  | {
      event: "view_older_posts_clicked";
      component_name:
        | "home_your_feed"
        | "home_focused_feed"
        | "club_feed"
        | "profile_feed"
        | "stock_feed"
        | "sector_feed"
        | "topic_feed";
    }
  | {
      event: "tour_skipped";
    }
  | {
      event: "tour_completed";
    }
  | {
      event: "popup_Initialised";
      pop_up_type: string;
    }
  | {
      event: "popup_cross_clicked";
      pop_up_type: string;
    }
  | {
      event: "popup_cta_clicked";
      pop_up_type: string;
      cta_text?: string;
    }
  | {
      event: "onboarding_module_skipped";
    }
  | {
      event: "onboarding_module_completed";
      stock_count?: number;
      sector_count?: number;
      topic_count?: number;
    }
  | {
      event: "onboarding_module_initialized";
    }
  | {
      event: "onoarding_module_profile_completed";
    }
  | {
      event: "onboarding_module_added_stocks";
    }
  | {
      event: "onboarding_module_added_sectors";
    }
  | {
      event: "onbaording_module_added_topics";
    }
  | {
      event: "user_logged_out";
    }
  | {
      event: "see_less_clicked";
      component_name: "web_left_menu_club" | "web_left_menu_suggested_people";
    }
  | {
      event: "see_more_clicked";
      component_name: "web_left_menu_club" | "web_left_menu_suggested_people";
    }
  | {
      event: "see_all_clicked";
      component_name:
        | "web_right_trending_stocks"
        | "web_right_trending_sectors"
        | "web_right_trending_topics"
        | "discover_experts_stockedge_analyst"
        | "discover_experts_market_experts"
        | "discover_experts_domain_experts"
        | "discover_trending_stocks"
        | "discover_trending_sectors"
        | "discover_trending_topics"
        | "discover_engaging_members";
    }
  | {
      event: "promotion_tab_clicked";
    }
  | {
      event: "post_notification_enabled";
    }
  | {
      event: "post_notification_disabled";
    }
  | {
      event: "user_engaged";
      icon_name: "upvote" | "downvote" | "share" | "comment" | "reply";
    }
  | {
      event: "post_submitted";
    }
  | {
      event: "create_post_floater_clicked";
    }
  | {
      event: "create_post_clicked";
      component_name: "home" | "club";
    }
  | {
      event: "user_unfollow_button_clicked";
      component_name: "profile";
    }
  | {
      event: "user_follow_button_clicked";
      component_name: UserFollowButtonClickedComponentName;
    }
  | {
      event: "user_button_clicked";
      component_name: UserButtonClicked;
    }
  | {
      event: "search_request_button_clicked";
      search_term_count: number;
    }
  | {
      event: "search_tag_added";
      search_tag_type: "dollar" | "people" | "club" | "topic";
    }
  | {
      event: "search_result_clicked";
      search_result_type: "dollar" | "people" | "club" | "topic";
    }
  | {
      event: "query_searched";
      search_text: string;
    }
  | {
      event: "search_initialized";
    }
  | {
      event: "upcoming_session_cta_clicked";
      component_name: "learn_upcoming_session";
    }
  | {
      event: "courses_clicked";
      component_name: "learn";
    }
  | {
      event: "webinars_clicked";
      component_name: "learn";
    }
  | {
      event: "club_leave_button_clicked";
      component_name: "club_details";
    }
  | {
      event: "club_join_button_clicked";
      component_name: ClubJoinButtonClickedComponentName;
    }
  | {
      event: "club_button_clicked";
      component_name: ClubButtonClicked;
    }
  | {
      event: "topic_unfollow_button_clicked";
      component_name: "topic_details";
    }
  | {
      event: "topic_follow_button_clicked";
      component_name: TopicFollowButtonClickedComponentName;
    }
  | {
      event: "topic_button_clicked";
      component_name: TopicButtonClicked;
    }
  | {
      event: "sector_unfollow_button_clicked";
      component_name: "sector_details";
    }
  | {
      event: "sector_follow_button_clicked";
      component_name: SectorFollowButtonClickedComponentName;
    }
  | {
      event: "sector_button_clicked";
      component_name:
        | "discover_trending_sectors"
        | "web_right_menu_trending_sectors"
        | "smart_feed_trending_sectors"
        | "club_sectors"
        | "watchlist_sectors"
        | "search_all"
        | "search_sectors"
        | "post"
        | "post_mention"
        | "comment"
        | "comment_mention"
        | "reply"
        | "reply_mention"
        | "trending_sectors";
    }
  | {
      event: "stock_unfollow_button_clicked";
      component_name: "stock_details";
    }
  | {
      event: "stock_follow_button_clicked";
      component_name: StockFollowButtonClickedComponentName;
    }
  | {
      event: "stock_button_clicked";
      component_name:
        | "discover_trending_stocks"
        | "web_right_menu_trending_stocks"
        | "smart_feed_trending_stocks"
        | "club_stocks"
        | "watchlist_stocks"
        | "search_all"
        | "search_stocks"
        | "post"
        | "post_mention"
        | "comment"
        | "comment_mention"
        | "reply"
        | "reply_mention"
        | "trending_stocks";
    }
  | {
      event: "upgrade_modal_initialised";
      component_name: UpgradeModalInitialised;
      feature_name_list: string;
    }
  | {
      event: "bottom_nav_bar_clicked";
      button_name: "Home" | "Discover" | "Notification" | "Learn" | "Profile";
    }
  | {
      event: "sidemenu_trending_button_clicked";
      component_name: "sidemenu_trending";
      button_name: "topics" | "sectors" | "stocks";
    }
  | {
      event: "sidemenu_expert_button_clicked";
      component_name: "sidemenu_experts";
      button_name: "stockedge_analysts" | "market_experts" | "domain_experts";
    }
  | {
      event: "onoarding_module_profile_completed";
    }
  | {
      event: "onboarding_module_skipped";
    }
  | {
      event: "onboarding_module_completed";
      stock_count: number;
      sector_count: number;
      topic_count: number;
    }
  | {
      event: "onboarding_module_initialized";
    }
  | {
      event: "onboarding_module_added_stocks";
    }
  | {
      event: "onboarding_module_added_sectors";
    }
  | {
      event: "onbaording_module_added_topics";
    }
  | {
      event: "post_to_your_wall";
    }
  | {
      event: "share_your_journey";
    }
  | {
      event: "submit_your_journey";
    }
  | {
      event: "web_achievement_promotion";
    }
  | {
      event: "set_default_feed";
      feed_name: string;
    }
  | {
      event: "smart_feed_achievement_promotion";
    }
  | {
      event: "clear_all_recent_search";
    }
  | {
      event: "clear_recent_search";
    }
  | {
      event: "clicked_on_recent_search";
    };

export const sendEvent = ({ event, ...params }: GAEvent) => {
  ReactGA.event(event, { ...params });
};
